////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Bower
@node_path: "../../../node_modules/";

// Font Awesome
@import "@{node_path}font-awesome/less/font-awesome.less";
// Core variables and mixins
@import "@{node_path}bootstrap/less/variables.less";
@import "@{node_path}bootstrap/less/mixins.less";

// Reset and dependencies
@import "@{node_path}bootstrap/less/normalize.less";
//@import "@{node_path}bootstrap/less/print.less";
//@import "@{node_path}bootstrap/less/glyphicons.less";

// Core CSS
@import "@{node_path}bootstrap/less/scaffolding.less";
@import "@{node_path}bootstrap/less/type.less";
//@import "@{node_path}bootstrap/less/code.less";
@import "@{node_path}bootstrap/less/grid.less";
//@import "@{node_path}bootstrap/less/tables.less";
@import "@{node_path}bootstrap/less/forms.less";
@import "@{node_path}bootstrap/less/buttons.less";

// Components
@import "@{node_path}bootstrap/less/component-animations.less";
@import "@{node_path}bootstrap/less/dropdowns.less";
@import "@{node_path}bootstrap/less/button-groups.less";
//@import "@{node_path}bootstrap/less/input-groups.less";
@import "@{node_path}bootstrap/less/navs.less";
@import "@{node_path}bootstrap/less/navbar.less";
//@import "@{node_path}bootstrap/less/breadcrumbs.less";
//@import "@{node_path}bootstrap/less/pagination.less";
//@import "@{node_path}bootstrap/less/pager.less";
//@import "@{node_path}bootstrap/less/labels.less";
//@import "@{node_path}bootstrap/less/badges.less";
//@import "@{node_path}bootstrap/less/jumbotron.less";
//@import "@{node_path}bootstrap/less/thumbnails.less";
//@import "@{node_path}bootstrap/less/alerts.less";
//@import "@{node_path}bootstrap/less/progress-bars.less";
//@import "@{node_path}bootstrap/less/media.less";
//@import "@{node_path}bootstrap/less/list-group.less";
//@import "@{node_path}bootstrap/less/panels.less";
//@import "@{node_path}bootstrap/less/responsive-embed.less";
//@import "@{node_path}bootstrap/less/wells.less";
@import "@{node_path}bootstrap/less/close.less";

// Components w/ JavaScript
@import "@{node_path}bootstrap/less/modals.less";
//@import "@{node_path}bootstrap/less/tooltip.less";
//@import "@{node_path}bootstrap/less/popovers.less";
//@import "@{node_path}bootstrap/less/carousel.less";

// Utility classes
@import "@{node_path}bootstrap/less/utilities.less";
@import "@{node_path}bootstrap/less/responsive-utilities.less";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@font-family-yeseva: 'Yeseva One', cursive;
@font-family-alice: 'Alice', serif;
@font-family-base: @font-family-alice;


@brand-color-light-grey: #e5e5e5;
@brand-color-red: #ee0000;


.clear {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}
.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.block-link {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
}
.block-img {
  display: block;
  width: 100%;
  > img {
    display: block;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}


.block-map, .block-video {
  width: 100%;
  height: 400px;
  margin: 0 auto;
  .map, .video {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    iframe {
      width: 100%;
      height: 100%;
    }
    @supports (object-fit: cover) {
      video {
        /*
         * Insert resets for the amazeballs css here
         */
        object-fit: cover;
      }
    }
  }
  @media (min-width: @screen-md-min) {
    height: 601px;
  }
}
@iframe-width-sm: @container-sm - @grid-gutter-width;
@iframe-width-md: @container-md - @grid-gutter-width;
@iframe-width-lg: @container-lg - @grid-gutter-width;
@iframe-height-sm: @iframe-width-sm / 1.78;
@iframe-height-md: @iframe-width-md / 1.78;
@iframe-height-lg: @iframe-width-lg / 1.78;
.block-video {
  width: 90vw;
  height: 56vw;
  @media (min-width: @screen-sm-min) {
    width: @iframe-width-sm;
    height: @iframe-height-sm;
  }
  @media (min-width: @screen-md-min) {
    width: @iframe-width-md;
    height: @iframe-height-md;
  }
  @media (min-width: @screen-lg-min) {
    width: @iframe-width-lg;
    height: @iframe-height-lg;
  }
}


html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  max-width: 2500px;
  margin-right: auto;
  margin-left: auto;
}
.body {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.wrapper {
  flex: 1 0 auto;
  overflow: hidden
}
@footer-bg-color: @site-bg-color;
@footer-copy-padding: 20px;
@footer-copy-color: #000000;
@footer-copy-font-size: 12px;
footer {
  flex: 0 0 auto;
  background-color: @footer-bg-color;
  .block-copyright {
    > div {
      .align-center;
      position: relative;
    }
    padding-top: @footer-copy-padding;
    padding-bottom: @footer-copy-padding;
    p {
      margin-bottom: 0;
      color: @footer-copy-color;
      font-size: @footer-copy-font-size;
    }
  }
}
@link-viva-width: 60px;
@link-viva-height: 14px;
.block-link-viva {
  position: absolute;
  top: 0;
  right: 15px;
  width: @link-viva-width;
  margin-left: auto;
  .link-viva {
    display: block;
    width: @link-viva-width;
    height: @link-viva-height;
    background-image: url("../images/viva_logo.png");
    opacity: 0.4;
    background-color: #a6a6a6;
    border-radius: 3px;
    box-shadow:         0px 0px 10px 0px rgba(50, 50, 50, 1);
    &:focus, &:hover {
      opacity: 0.8;
      text-decoration: none;
      box-shadow:         0px 0px 10px 0px rgba(70, 70, 70, 1);
    }
    &:active {
      opacity: 1;
    }
  }
}

.make-navbar(@navbar-height, @navbar-bg-color, @navbar-link-padding,
@navbar-link-color, @navbar-link-font-size, @navbar-link-font-weight, @navbar-link-bg-color,
@navbar-link-color-hover, @navbar-link-bg-color-hover,
@navbar-link-color-active, @navbar-link-bg-color-active) {
  min-height: @navbar-height;
  margin-bottom: 0;
  background-color: @navbar-bg-color;
  border: none;
  border-radius: 0;
  .navbar-nav {
    > li > a, > li > div > p {
      .align-center;
      height: @navbar-height;
      padding: @navbar-link-padding;
      color: @navbar-link-color;
      font-size: @navbar-link-font-size;
      font-weight: @navbar-link-font-weight;
      background-color: @navbar-link-bg-color;
      &:hover, &:focus {
        color: @navbar-link-color-hover;
        background-color: @navbar-link-bg-color-hover;
      }
      &:active {
        color: @navbar-link-color-active;
        background-color: @navbar-link-bg-color-active;
      }
    }
    > .active > a {
      &, &:hover, &:focus {
        color: @navbar-link-color-active;
        background-color: @navbar-link-bg-color-active;
      }
    }
  }
}
.make-navbar-header-img(@navbar-header-height, @nav-brand-width, @nav-brand-height, @nav-brand-margin,
@toggle-margin, @toggle-padding, @toggle-bg-color, @toggle-border, @toggle-border-radius,
@icon-bar-width, @icon-bar-height, @icon-bar-border-radius, @icon-bar-bg-color, @icon-bar-margin-top,
@toggle-bg-color-active, @toggle-border-active, @icon-bar-bg-color-active) {
  .navbar-header {
    .align-center;
    justify-content: flex-start;
    height: @navbar-header-height;
    .navbar-brand {
      display: block;
      width: @nav-brand-width;
      height: @nav-brand-height;
      margin: @nav-brand-margin;
      padding: 0 !important;
      #logo {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    >.navbar-toggle {
      margin: @toggle-margin;
      margin-left: @toggle-margin;
      padding: @toggle-padding;
      background-color: @toggle-bg-color;
      border: @toggle-border;
      border-radius: @toggle-border-radius;
      .icon-bar {
        width: @icon-bar-width;
        height: @icon-bar-height;
        border-radius: @icon-bar-border-radius;
        background-color: @icon-bar-bg-color;
      }
      .icon-bar + .icon-bar {
        margin-top: @icon-bar-margin-top;
      }
      &:active {
        background-color: @toggle-bg-color-active;
        border: @toggle-border-active;
        .icon-bar {
          background-color: @icon-bar-bg-color-active;
        }
      }
    }
  }
}
.navbar-default {
  .make-navbar(40px, #000000, 10px 0,
          #ffffff, 15px, 400, transparent,
          @brand-color, transparent,
          @brand-color, transparent);
  .make-navbar-header-img(60px, 80px, 20px, 0 10px,
          10px, 10px, transparent, 1px solid transparent, 0,
          29px, 1px, 0, #ffffff, 6px,
          transparent, 1px solid #ffffff, #ffffff);
  .navbar-toggle {
    .pull-left;
    position: relative;
    z-index: 1000;
  }
}

// ------------------------------- 404
.page-404 {
  .text-center;
  width: 100%;
  height: 100%;
  color: #ffffff;
  background: #282828 url("/media/images/404.jpg") center no-repeat;
  background-size: cover;
  .inner-block {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 2%;
  }
  .title {
    .text-uppercase;
    font-family: @font-family-yeseva;
    font-size: 30px;
    letter-spacing: 3px;
  }
  .b-404 {
    margin-bottom: 3%;
    font-family: @font-family-yeseva;
    font-size: 100px;
    line-height: 1;
  }
  .text {
    margin-bottom: 15%;
    max-width: 350px;
    margin-right: auto;
    margin-left: auto;
    font-size: 16px;
  }
  .btn-default {
    .maker-btn(17px, 30px, 14px, #ffffff, transparent,  @color-green, 0,
            #ffffff, @color-green, @color-green,
            #ffffff, @color-green, @color-green);
    .text-uppercase;
    margin-top: 15px;
  }
  @media (min-width: @screen-sm-min) {
    .inner-block {
      width: 50%;
      margin-right: auto;
      margin-left: auto;
      padding-top: 10%;
    }
    .title {
      font-size: 40px;
    }
    .b-404 {
      font-size: 180px;
    }
    .text {
      font-size: 18px;
    }
    .btn-default {
      .maker-btn(27px, 40px, 16px, #ffffff, transparent, @color-green, 0,
              #ffffff, @color-green, @color-green,
              #ffffff, @color-green, @color-green);
    }
  }
}

//------------------------------------------------------------------------------------

@brand-color: @color-yellow;
@color-yellow: #ffce49;
@color-brown: #91653b;
@site-bg-color: #ffffff;
// Maker for titles
.make-title(@title-margin-top, @title-margin-bottom, @title-color, @title-font-size, @title-font-weight) {
  display: block;
  margin-top: @title-margin-top;
  margin-bottom: @title-margin-bottom;
  color: @title-color;
  font-size: @title-font-size;
  font-weight: @title-font-weight;
}
.maker-triangle(@triangle-height, @triangle-direction, @triangle-bg-color) {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: @triangle-height;
    background: linear-gradient(to @triangle-direction, @triangle-bg-color 50%, transparent 50%);
  }
}
// Maker for dropdown-menu
.maker-dropdown-menu(@dropdown-width, @dropdown-margin-top, @dropdown-padding, @dropdown-bg,
@dropdown-border, @dropdown-border-radius,
@dropdown-link-padding, @dropdown-link-color, @dropdown-link-font-size, @dropdown-link-font-weight,
@dropdown-link-hover-color, @dropdown-link-hover-bg,
@dropdown-link-active-color, @dropdown-link-active-bg) {
  &.open > .dropdown-toggle.btn-default {
    color: @dropdown-link-hover-color;
    background-color: @dropdown-link-hover-bg;
    border: none !important;
  }
  .dropdown-toggle {
    padding: @dropdown-link-padding;
  }
  .dropdown-menu {
    width: @dropdown-width;
    min-width: @dropdown-width;
    margin-top: @dropdown-margin-top;
    padding: @dropdown-padding;
    background-color: @dropdown-bg;
    border: @dropdown-border;
    border-radius: @dropdown-border-radius;
    li > a, li > .btn {
      .align-center;
      padding: @dropdown-link-padding;
      color: @dropdown-link-color;
      font-size: @dropdown-link-font-size;
      font-weight: @dropdown-link-font-weight;
      line-height: 1 !important;
      white-space: unset !important;
      &:hover,
      &:focus {
        text-decoration: none;
        color: @dropdown-link-hover-color;
        background-color: @dropdown-link-hover-bg;
      }
    }
    .active > a {
      &,
      &:hover,
      &:focus {
        color: @dropdown-link-active-color;
        text-decoration: none;
        outline: 0;
        background-color: @dropdown-link-active-bg;
      }
    }
  }
}
.maker-btn(@btn-padding-top, @btn-padding-right, @btn-font-size, @btn-color,
@btn-bg-color, @btn-border-color, @btn-border-radius,
@btn-hover-color, @btn-hover-bg-color, @btn-border-color-hover,
@btn-color-active, @btn-bg-color-active, @btn-border-color-active) {
  padding: @btn-padding-top @btn-padding-right;
  color: @btn-color !important;
  font-size: @btn-font-size;
  line-height: 1 !important;
  background-color: @btn-bg-color !important;
  border-color: @btn-border-color !important;
  border-radius: @btn-border-radius !important;
  &:hover, &:focus, &.active {
    color: @btn-hover-color !important;
    background-color: @btn-hover-bg-color !important;
    border-color: @btn-border-color-hover !important;
    box-shadow: none !important;
  }
  &:active, &:active:hover {
    color: @btn-color-active !important;
    background-color: @btn-bg-color-active !important;
    border-color: @btn-border-color-active !important;
    box-shadow: none !important;
  }
}
.make-arrow-right(@arrow-width, @arrow-border-width, @arrow-color) {
  width: @arrow-width;
  height: @arrow-width;
  border-top: solid @arrow-border-width @arrow-color;
  border-right: solid @arrow-border-width @arrow-color;
  transform: rotate(45deg);
}
.make-arrow-left(@arrow-width, @arrow-border-width, @arrow-color) {
  width: @arrow-width;
  height: @arrow-width;
  border-top: solid @arrow-border-width @arrow-color;
  border-right: solid @arrow-border-width @arrow-color;
  transform: rotate(-135deg);
}
// Grid classes
.flex-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
}
.flex-item {
  position: relative;
  display: flex;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  // 3 items in row
  @media (min-width: @screen-sm-min) {
    width: ~'calc((100% - 2 * 10px)/3)';
    margin-left: 10px;
    &:nth-child( 3n + 1 ) {
      margin-left: 0;
    }
  }
  // 4 items in row
  //@media (min-width: @screen-md-min) {
  //  width: ~'calc((100% - 3 * 10px)/4)';
  //  margin-left: 10px;
  //  &:nth-child( 3n + 1 ) {
  //    margin-left: 10px;
  //  }
  //  &:nth-child( 4n + 1 ) {
  //    margin-left: 0;
  //  }
  //}
  // 5 items in row
  //@media (min-width: @screen-lg-min) {
  //width: ~'calc((100% - 4 * 10px)/5)';
  //margin-left: 10px;
  //&:nth-child( 4n + 1 ) {
  //  margin-left: 10px;
  //}
  //&:nth-child( 5n + 1 ) {
  //  margin-left: 0;
  //}
  //}
}
.flex-item-3 {
  position: relative;
  display: flex;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  // 3 items in row
  @media (min-width: @screen-sm-min) {
    width: ~'calc((100% - 2 * 30px)/3)';
    margin-left: 30px;
    &:nth-child( 3n + 1 ) {
      margin-left: 0;
    }
  }
}


@mail-color: @color-brown;
@mail-font-size: 18px;
@mail-text-decoration: underline;
@mail-color-hover: @color-brown;
@mail-text-decoration-hover: none;
.mail {
  color: @mail-color;
  font-size: @mail-font-size;
  text-decoration: @mail-text-decoration;
  &:hover, &:focus, &:active {
    color: @mail-color-hover;
    text-decoration: @mail-text-decoration-hover;
  }
}

@logo-width: 164px;
@logo-height: 40px;
.block-logo {
  .text-center;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  z-index: 999;
  .logo {
    display: block;
    width: @logo-width;
    height: @logo-height;
    margin: 0 auto;
    transform: scale(0.5);
  }
  &:hover, &:focus, &.active {

  }
  @media (min-width: @screen-sm-min) {
    top: 15px;
    left: 15px;
    right: unset;
    .logo {
      transform: scale(1);
    }
  }
}
.menu-lang {
  .maker-dropdown-menu(40px, 0, 0, #000000,
          none, 0,
          0, #ffffff, 12px, 400,
          @brand-color, #424237,
          @brand-color, #424237);
  .text-uppercase;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  font-family: @font-family-yeseva;
  .dropdown-toggle {
    .text-uppercase;
    width: 40px;
    height: 40px;
    color: @brand-color;
    font-size: 12px;
    background-color: #424237;
    border: none;
    border-radius: 0;
    &:hover, &:hover, &:active, &.active {
      color: #000000 !important;
      background-color: darken(@brand-color, 15%) !important;
      border: none !important;
    }
  }
  &.open > .dropdown-toggle.btn-default {
    color: #000000 !important;
    background-color: darken(@brand-color, 15%) !important;
    border: none !important;
  }
  .dropdown-menu {
    > li > a {
      height: 35px;
    }
  }
  @media (min-width: @screen-sm-min) {
    top: 0;
    right: 0;
    .maker-dropdown-menu(70px, 0, 0, #000000,
            none, 0,
            0, #ffffff, 18px, 400,
            @brand-color, #424237,
            @brand-color, #424237);
    .dropdown-toggle {
      .text-uppercase;
      width: 70px;
      height: 70px;
      color: @brand-color;
      font-size: 18px;
      background-color: #424237;
      border: none;
      border-radius: 0;
      &:hover, &:hover, &:active, &.active {
        color: #000000 !important;
        background-color: darken(@brand-color, 15%) !important;
        border: none !important;
      }
    }
  }
}

.title-location {
  .make-title(0, 36px, #000000, 16px, 400);
  display: flex;
  .location {
    padding-top: 20px;
  }
  .location-number {
    .align-center;
    margin-left: 15px;
    padding-bottom: 5px;
    color: @brand-color;
    background-color: transparent;
    border-radius: 50%;
  }
}
.title-block {
  .make-title(0, 34px, #000000, 45px, 400);
  .text-uppercase;
  max-width: 500px;
  font-family: @font-family-yeseva;
}

.block-switches {
  .hidden-xs;
  .text-center;
  margin-top: 20px;
  > p {
    color: @color-brown;
  }
  .list-switches {
    .list-unstyled;
    .list-inline;
    margin-bottom: 0;
    .item-switch {
      > a {
        display: flex;
        justify-content: center;
        width: 28px;
        height: 28px;
        color: @color-brown;
        font-size: 16px;
        background-color: transparent;
        border: 1px solid @color-brown;
        border-radius: 50%;
      }
      &:focus > a, &:hover > a, &:active > a, &.active > a {
        color: @brand-color;
        background-color: @color-brown;
        text-decoration: none;
      }
    }
  }
  @media (min-width: @screen-lg-min) {
    margin-bottom: 30px;
  }
}
.list-slider-switch {
  .hidden-xs;
  position: fixed;
  top: 40%;
  right: 0;
  z-index: 4;
  > ul {
    .list-unstyled;
    padding-right: 4vw;
    > li {
      .align-center;
      position: relative;
      width: 40px;
      height: 40px;
      margin-bottom: 25px;
      color: #ffffff;
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: -4vw;
        width: 4vw;
        height: 1px;
        background-color: currentColor;
      }
      > a {
        .align-center;
        width: inherit;
        height: inherit;
        color: #ffffff;
        font-size: 24px;
        z-index: 1;
      }
      &:hover, &:focus, &:active, &.active {
        color: @brand-color;
        > a {
          text-decoration: none;
        }
        &:before {
          content: '';
          position: absolute;
          top: 1px;
          left: 0;
          display: block;
          width: 40px;
          height: 40px;
          background-color: transparent;
          border: 1px solid currentColor;
          border-radius: 50%;
        }
      }
    }
  }
  .icon-switch-home {
    content: '';
    display: block;
    width: 17px;
    height: 21px;
    background: url("/media/images/slider_switch_active.png") 0 3px no-repeat;
  }
}

.anchor-map {
  display: inline-block;
  color: @color-brown;
  .fa {
    margin-right: 5px;
    font-size: 22px;
  }
  span {
    display: inline-block;
    color: inherit;
    font-size: 16px;
    border-bottom: 1px dashed currentColor;
  }
  &:hover, &:focus, &:active {
    color: @color-brown;
    text-decoration: none;
    span {
      border-bottom: 1px dashed transparent;
    }
  }
}

@color-green: #708622;
.page-home {
  position: relative;
  .title-location {
    .make-title(0, 10px, @brand-color, 15px, 400);
    display: flex;
    .location {
      .text-uppercase;
      padding-top: 20px;
    }
    .location-number {
      width: 46px;
      height: 46px;
      font-size: 40px;
      border: 2px solid @brand-color;
    }
  }
  .slide-block {
    position: relative;
    width: 100%;
  }
  .block-video, .bg-image {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    .video {
      max-width: unset;
      video {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .block-on-click {
    background-color: #5c5c5c;
    .btn-default {
      .maker-btn(20px, 30px, 14px, #ffffff, transparent, @color-green, 0,
              #ffffff, @color-green, @color-green,
              #ffffff, @color-green, @color-green);
      .text-uppercase;
      margin-top: 15px;
      border-width: 2px;
    }
  }
  .slide-1 {
    .bg-image {
      background: url(/content/slide_1.jpg) center no-repeat;
      background-size: cover;
    }
    .block-on-click {
      .bg-image {
        background: url(/content/locations/gaivka/index/location_desc.jpg) no-repeat;
        background-size: cover;
      }
    }
  }
  .slide-2 {
    .bg-image {
      background: url(/content/slide_2.jpg) center no-repeat;
      background-size: cover;
    }
    .block-on-click {
      .bg-image {
        background: url(/content/locations/makarovitsya/index/location_desc.jpg) no-repeat;
        background-size: cover;
      }
    }
  }
  .slide-3 {
    .bg-image {
      background: url(/content/slide_3.jpg) center no-repeat;
      background-size: cover;
    }
    .block-on-click {
      .bg-image {
        background: url(/content/locations/korchin/index/location_desc.jpg) no-repeat;
        background-size: cover;
      }
    }
  }
  .block-desc {
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 10px;
    padding-bottom: 25px;
    p {
      font-size: 15px;
    }
  }
  .title-block, p {
    color: #ffffff;
  }
  .title-block {
    position: relative;
    margin-bottom: 20px;
    font-size: 22px;
  }
  @media (min-width: @screen-sm-min) {
    .slide-block {
      height: 100vh;
    }
    .title-location {
      .make-title(0, 36px, @brand-color, 18px, 400);
      display: flex;
      .location {
        .text-uppercase;
        padding-top: 35px;
      }
      .location-number {
        width: 69px;
        height: 69px;
        font-size: 60px;
        border: 3px solid @brand-color;
      }
    }
    .block-on-click {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: unset;
      .btn-default {
        .maker-btn(20px, 30px, 14px, #ffffff, transparent, @color-green, 0,
                #ffffff, @color-green, @color-green,
                #ffffff, @color-green, @color-green);
        .text-uppercase;
        margin-top: 60px;
        border-width: 2px;
      }
      .bg-image {
        filter: brightness(0.45);
      }
      .title-block {
        &:hover, &:focus, &:hover, &.active {
          cursor: default;
        }
      }
    }
    .title-block {
      position: relative;
      margin-bottom: 34px;
      font-size: 35px;
      .icon-arrow-right {
        position: absolute;
        top: 35%;
        right: 0;
        .make-arrow-right(30px, 3px, currentColor);
      }
      &:hover, &:focus, &:hover, &.active {
        cursor: pointer;
      }
    }
    .block-desc {
      width: 50vw;
      max-width: 450px;
      margin-left: auto;
      padding-top: 10%;
      margin-right: 10vw;
      p {
        .hidden-sm;
        font-size: 19px;
      }
    }
  }
  @media (min-height: 900px) {
    .title-block {
      font-size: 60px;
    }
    .block-desc {
      padding-top: 15%;
    }
  }
  @media (min-width: @screen-lg-min) {
    .title-block {
      position: relative;
      margin-bottom: 34px;
      .icon-arrow-right {
        position: absolute;
        top: 35%;
        right: 0;
        .make-arrow-right(30px, 3px, currentColor);
      }
      &:hover, &:focus, &:hover, &.active {
        cursor: pointer;
      }
    }
    .block-desc {
      max-width: 540px;
    }
  }
  @media (min-width: 1500px) {
    .block-desc {
      margin-right: 20vw;
    }
  }
}
.slide-0 {
  z-index: 3;
  min-height: 350px;
  background-color: #5c5c5c;
  .bg-image {
    background: url(/media/images/bg_video.jpg) center no-repeat;
    background-size: cover;
  }
  .block-desc {
    position: relative;
    width: unset;
    margin-left: unset;
    padding-top: 75%;
    p {
      position: relative;
      color: #ffffff;
      font-size: 15px;
      &:before {
        content: '';
        position: absolute;
        top: -40px;
        left: -12px;
        display: block;
        width: 60px;
        height: 43px;
        background: url("/media/images/quote.png") no-repeat;
        transform: scale(0.5);
      }
      &:after {
        content: '';
        position: absolute;
        bottom: -40px;
        right: 0;
        display: block;
        width: 60px;
        height: 43px;
        background: url("/media/images/quote.png") no-repeat;
        background-position-x: -65px;
        transform: scale(0.5);
      }
    }
  }
  @media (min-width: @screen-sm-min) {
    .block-desc {
      width: 50%;
      min-height: 400px;
      padding-top: 30%;
      p {
        font-size: 30px;
        &:before {
          top: -50px;
          left: 0;
          transform: scale(1);
        }
        &:after {
          bottom: -50px;
          transform: scale(1);
        }
      }
    }
  }
}

.page-location {
  font-size: 15px;
  background-color: @site-bg-color;
  .title-location {
    .make-title(0, 20px, #000000, 16px, 400);
    display: flex;
    padding-top: 10px;
    .location {
      padding-top: 20px;
    }
    .location-number {
      width: 46px;
      height: 46px;
      font-size: 40px;
      border: 2px solid @brand-color;
    }
  }
  .title-block {
    .make-title(0, 10px, #000000, 30px, 600);
  }
  .location-desc {
    position: relative;
    .block-desc {
      padding-bottom: 20px;
    }
    .block-img {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 5vh;
        display: block;
      }
      > img {
        max-width: 2500px;
      }
      svg {
        position: absolute;
        bottom: -1px;
        right: 0;
        left: 0;
        fill: @site-bg-color;
      }
    }
  }
  .block-hunting {
    padding-top: 2vh;
    padding-bottom: 10vh;
    .list-victims {
      //.flex-container;
      //justify-content: center;
      .text-center;
      padding-top: 2vh;
      .item-victim {
        //.flex-item-3;
        //flex-direction: column;
        margin-bottom: 2vh;
        .block-img > img {
          max-width: 160px;
        }
        .victim-desc {
          padding-left: 25px;
        }
        .title {
          position: relative;
          font-size: 20px;
          &:before {
            content: '';
            position: absolute;
            top: 5px;
            left: -25px;
            display: block;
            width: 19px;
            height: 19px;
            padding: 4px;
            background-color: @brand-color;
            background-clip: content-box;
            border: 3px solid @brand-color;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .block-opportunities {
    .clear;
    position: relative;
    overflow: hidden;
    margin-bottom: 10vh;
    .title-block, .block-text > div {
      position: relative;
      z-index: 1;
      max-width: 550px;
      .title {
        font-size: 20px;
      }
    }
    .block-photo {
      margin-bottom: 20px;
      .block-img > img {
        max-width: 655px;
      }
    }
  }
  .block-gallery-photo, .block-gallery-video {
    padding-top: 40px;
    padding-bottom: 5vh;
    background-color: #000000;
    .title-block {
      .text-center;
      margin-left: auto;
      margin-right: auto;
      color: #ffffff;
    }
    .item-main > img {
      max-width: 970px;
      border: 2px solid #858585;
      &:hover, &:focus, &.active {
        cursor: pointer;
      }
    }
  }
  .block-contacts {
    padding-top: 40px;
    .title-block {
      .text-center;
      margin-left: auto;
      margin-right: auto;
    }
    .block-adress, .block-telephones {
      margin-bottom: 15px;
    }
    .number {
      display: block;
      font-size: 24px;
      @media (min-width: 400px) {
        display: inline-block;
        font-size: 22px;
      }
    }
  }
  @media (min-width: @screen-md-min) {
    font-size: 16px;
    .title-block {
      .make-title(0, 34px, #000000, 50px, 400);
      max-width: 600px;
    }
    .location-desc {
      .block-desc {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
      }
      .block-text {
        .clear;
        .title-block {
          .pull-left;
          width: 40%;
          padding-right: 30px;
        }
        > div {
          .pull-right;
          width: 60%;
          max-width: 500px;
          padding-top: 25px;
          padding-bottom: 10px;
          padding-right: 10px;
          padding-left: 10px;
        }
      }
    }
    .block-hunting {
      .block-text {
        width: 60%;
      }
      .list-victims {
        padding-left: 5vh;
        columns: 3;
        .item-victim {
          display: inline-block;
          max-width: 220px;
          vertical-align: top;
          .block-img {
            padding-left: 30px;
          }
          .block-img > img {
            margin-right: unset;
            margin-left: unset;
          }
        }
      }
    }
    .block-opportunities {
      .block-photo {
        position: absolute;
        top: 0;
        right: -22%;
        .block-img {
          position: relative;
          svg {
            position: absolute;
            top: 0;
            bottom: 0;
            left: -1px;
            fill: @site-bg-color;
          }
        }
      }
      .block-text  {
        > div {
          .title {
            font-size: 24px;
          }
        }
      }
    }
    .block-contacts {
      .block-adress, .block-telephones {
        .pull-left;
        width: 50%;
        margin-bottom: 35px;
      }
    }
  }
  @media (min-width: @screen-lg-min) {
    .block-opportunities {
      .block-photo {
        right: -10%;
      }
    }
  }
  @media (min-width: 1280px) {
    .block-opportunities {
      .block-photo {
        right: 0;
      }
    }
  }
  @media (min-width: 1800px) {
    .block-opportunities {
      .block-photo {
        right: 0;
        .block-img {
          > img {
            max-width: unset;
          }
        }
      }
    }
  }
}

@svg-width: 20vw;
@svg-margin-left-sm: 5vw;
@svg-margin-left: 20vw;
#progress-line {
  .hidden-xs;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: @svg-margin-left-sm;
  width: @svg-width;
  height: 100%;
  @media (min-width: @screen-md-min) {
    left: @svg-margin-left;
  }
}
.cls-1 {
  fill: none;
  stroke: transparent;
  stroke-linecap: round;
  stroke-width: 5px;
  fill-rule: evenodd;
}
.cls-2 {
  fill: none;
  stroke: @brand-color;
  stroke-width: 5px;
  fill-rule: evenodd;
}

// jcarousel styles
.jcarousel-wrapper {
  margin: 20px auto;
  position: relative;
}
/** Carousel **/
.jcarousel {
  position: relative;
  overflow: hidden;
  width: 88%;
  margin-right: 6%;
  margin-left: 6%;
  @media (min-width: 1200px) {
    padding-left: 17px;
  }
}
.jcarousel ul {
  width: 20000em;
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
}
.jcarousel li {
  width: 200px;
  float: left;
}
.jcarousel img {
  display: block;
  max-width: 100%;
  height: auto !important;
  border: 2px solid transparent;
  &:hover, &:focus, &:hover, &.active {
    cursor: pointer;
    border: 2px solid #858585;
  }
}

/** Carousel Controls **/
.jcarousel-control-prev,
.jcarousel-control-next {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  text-align: center;
}
.jcarousel-control-prev {
  left: 4px;
  .icon-arrow-left {
    .make-arrow-left(20px, 2px, #666666);
  }
}
.jcarousel-control-next {
  right: -5px;
  .icon-arrow-right {
    .make-arrow-right(20px, 2px, #666666);
  }
}


// Fade in circle
.fadeInCircle {
  opacity:0;
  position: relative;
}
.slide-inner {
  opacity: 0;
}

@keyframes home-block-on-click {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.block-show {
  display: block !important;
  .bg-image {
    animation: home-block-on-click 1s ease 1;
  }
}

/* Make animation NOTICE: add px to dasharray and dashoffset for ie browser*/
.cls-2 {
  stroke-dasharray: 3137.66px, 3137.66px;
  stroke-dashoffset: 3137.66px;
}



.first-slide-animation {
  stroke-dasharray: 3137.66px;
  stroke-dashoffset: 3137.66px;
  animation: dashFirts 2s linear forwards;
 webkit-animation:  dashFirts 2s linear forwards;
}

@keyframes dashFirts {
  to {
      stroke-dashoffset: 3137.66px;
  }
}

.second-slide-animation {
  stroke-dasharray: 3137.66px;
  stroke-dashoffset: 2600.66px;
  animation: dashSecond 1.5s linear forwards;
 webkit-animation:  dashSecond 1.5s linear forwards;
}

@keyframes dashSecond {
  to {
      stroke-dashoffset: 2091.78px;
  }
}

.third-slide-animation {
  stroke-dasharray: 3137.66px, 3137.66px;
  stroke-dashoffset: 2091.78px;
  animation: dashThird 1.5s linear forwards;
 webkit-animation:  dashThird 1.5s linear forwards;
}

@keyframes dashThird {
  to {
      stroke-dashoffset: 1045.89px;
  }
}

.fourth-slide-animation {
  stroke-dasharray: 3137.66px, 3137.66px;
  stroke-dashoffset: 1045.89px;
  animation: dashFourth 1.5s linear forwards;
 webkit-animation:  dashFourth 1.5s linear forwards;
}

@keyframes dashFourth {
  to {
      stroke-dashoffset: 465.533px;
  }
}


/* Reverse animation */

.second-slide-animation-reverse {
  stroke-dasharray: 3137.66px;
  stroke-dashoffset: 2091.78px;
  animation: dashSecondReverse 1.5s linear forwards;
 webkit-animation:  dashSecond 1.5s linear forwards;
}

@keyframes dashSecondReverse {
  to {
      stroke-dashoffset: 3137.66px;
  }
}

.third-slide-animation-reverse {
  stroke-dasharray: 3137.66px, 3137.66px;
  stroke-dashoffset: 1045.89px;
  animation: dashThirdReverse 1.5s linear forwards;
 webkit-animation:  dashThird 1.5s linear forwards;
}

@keyframes dashThirdReverse {
  to {
      stroke-dashoffset: 2091.78px;
  }
}

.fourth-slide-animation-reverse {
  stroke-dasharray: 3137.66px, 3137.66px;
  stroke-dashoffset: 465.533px;
  animation: dashFourthReverse 1.5s linear forwards;
 webkit-animation:  dashFourth 1.5s linear forwards;
}

@keyframes dashFourthReverse {
  to {
      stroke-dashoffset: 1045.89px;
  }
}


/*  PReloader */
#page-preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #000;
  z-index: 100500;
}

#page-preloader .spinner {
  width: 100%;
  height: 100%;
  position: absolute;
  background: url('/content/preloader.svg') no-repeat 50% 50%;

}

